import { Component, OnInit } from '@angular/core';
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  Platform,
  IonRouterLink,
} from '@ionic/angular/standalone';
import { combineLatest, firstValueFrom, Observable, filter, map } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Article } from './models/article';
import { Page } from './models/page';
import { StateService } from './services/state.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink } from '@angular/router';
import { SeoContentService } from './services/seo-content.service';
import packageInfo from '../../package.json';
import { StorageService } from './services/storage.service';
import { LanguageService } from './services/language.service';
import { SeoSocialShareData, SeoSocialShareService } from 'ngx-seo';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { RateService } from './services/rate.service';
import { inject } from '@vercel/analytics';
import { FirebaseAnalyticsService } from './services/firebase-analytics.service';
import { MedicalTrail } from 'src/app/models/medical-trail';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { isNoMonitorAndProd } from './helpers/check-for-monitor-and-prod.helper';
import { Capacitor } from '@capacitor/core';
import { captureException } from './helpers/error-capture.helper';
import { YoutubeService } from './services/youtube.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [
    RouterLink,
    NgFor,
    NgIf,
    AsyncPipe,
    TranslateModule,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    IonLabel,
    IonRouterOutlet,
    IonRouterLink,
  ],
})
export class AppComponent implements OnInit {
  articles$: Observable<Array<Article>> | undefined = undefined;
  pages$: Observable<Array<Page>> | undefined = undefined;
  medicalTrail: MedicalTrail | undefined = undefined;

  constructor(
    private platform: Platform,
    private stateService: StateService,
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private browserTitleService: SeoContentService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private storageService: StorageService,
    private seoSocialShareService: SeoSocialShareService,
    private languageService: LanguageService,
    private rateService: RateService,
    private youtubeService: YoutubeService,
  ) {
    translate.addLangs(['en', 'da', 'sv', 'au']);
    translate.setDefaultLang(this.languageService.getSystemLanguage());

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      // pause all YouTube videos when navigating away from the page
      document.querySelectorAll('iframe').forEach((item) => {
        item?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      });
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.youtubeService.init();
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => {
          const child = this.activatedRoute.firstChild;
          return this.browserTitleService.getTitle(child?.snapshot.data.title || '', (event as NavigationEnd).url);
        }),
      )
      .subscribe(async (seoSocialShareDataPromise: Promise<SeoSocialShareData>) => {
        try {
          const seoContent = await seoSocialShareDataPromise;
          this.seoSocialShareService.setData(seoContent);
          if (seoContent.url && seoContent.title) {
            this.firebaseAnalyticsService.pageInit(seoContent.url, seoContent.title);
          }
        } catch (error) {
          captureException(error, 'info', 'seo-social-share');
        }
      });
  }

  async ngOnInit() {
    await this.platform.ready();
    this.stateService.medicalTrail$.subscribe((medicalTrail) => {
      this.medicalTrail = medicalTrail;
    });
    this.articles$ = combineLatest([this.stateService.articles$, this.stateService.secretArticles$]).pipe(
      map(([articles, secretArticles]) => [...articles, ...secretArticles]),
      // sort articles by order
      map((articles) => articles.sort((a, b) => a.order - b.order)),
    );

    this.pages$ = this.stateService.pages$;

    const platforms = this.platform.platforms().join('-');
    this.firebaseAnalyticsService.trackEvent('start_session', { platforms });

    let sessions = await firstValueFrom(this.storageService.get$('sessions'));
    sessions = sessions || 0;
    this.storageService.set$('sessions', parseInt(sessions) + 1);

    if (platforms.includes('capacitor')) {
      try {
        if (this.platform.is('android')) {
          await StatusBar.setBackgroundColor({ color: '#4a275e' });
          await StatusBar.setOverlaysWebView({ overlay: false });
        }
        await StatusBar.setStyle({ style: Style.Dark });
        await SplashScreen.hide();
        if (sessions > 1) {
          this.rateService.init();
        }
      } catch (error) {
        captureException(error, 'error', 'plugins');
      }
    }

    const startFirst = await firstValueFrom(this.storageService.get$('startFirst'));
    if (!startFirst) {
      this.firebaseAnalyticsService.trackEvent('start', { version: packageInfo.version, platforms });
      this.storageService.set$('startFirst', packageInfo.version);
    } else if (startFirst !== packageInfo.version) {
      this.firebaseAnalyticsService.trackEvent('update', { version: packageInfo.version, platforms });
      this.storageService.set$('startFirst', packageInfo.version);
    }

    if (isNoMonitorAndProd() && inject && !Capacitor.isNativePlatform()) {
      try {
        inject();
      } catch (error) {
        captureException(error, 'info', 'vercel-analytics-inject');
      }
    }
  }
}
