import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../services/state.service';
import { IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-extra',
  templateUrl: './extra-page.component.html',
  styleUrls: ['./extra-page.component.scss'],
  imports: [IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent],
})
export class ExtraPageComponent implements OnInit {
  title: string | undefined = undefined;
  content: string | undefined = undefined;
  icon: string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.stateService.pages$.subscribe(() => this.setPageContent());
  }

  setPageContent() {
    const page = this.stateService.getExtraPage(this.route.snapshot.paramMap.get('slug') || '');
    if (!page) {
      this.router.navigate(['']);
      return;
    }
    const { menutitle, content, icon } = page;
    this.title = menutitle;
    this.content = content;
    this.icon = icon;
  }
}
