import { Component, OnInit } from '@angular/core';
import { SettingsLanguageComponent } from '../settings-language/settings-language.component';
import { StateService } from '../../../services/state.service';
import { SettingsTreatmentPlaceComponent } from '../settings-treatment-place/settings-treatment-place.component';
import { SettingsMedicalTrailComponent } from '../../../pages/settings/settings-medical-trail/settings-medical-trail.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, UpperCasePipe } from '@angular/common';
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings-home',
  templateUrl: './settings-home.component.html',
  styleUrls: ['./settings-home.component.scss'],
  imports: [
    NgIf,
    UpperCasePipe,
    TranslateModule,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonContent,
    IonItem,
    IonList,
    IonLabel,
  ],
})
export class SettingsHomeComponent implements OnInit {
  currentLanguage: string | undefined = undefined;
  currentTreatmentPlace: string | undefined = undefined;
  medicalTrail: string | undefined = undefined;

  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.stateService.currentLanguage$.subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
    });
    this.stateService.currentTreatmentPlace$.subscribe((currentTreatmentPlace) => {
      this.currentTreatmentPlace = currentTreatmentPlace;
    });
    this.stateService.medicalTrail$.subscribe((medicalTrail) => {
      if (medicalTrail?.includes('enrolled-with-video')) {
        this.medicalTrail = 'enrolled-with-video';
      } else if (medicalTrail?.includes('enrolled-without-video')) {
        this.medicalTrail = 'enrolled-without-video';
      } else {
        this.medicalTrail = medicalTrail;
      }
    });
  }

  selectLanguage() {
    const nav = document.querySelector('ion-nav');
    nav?.push(SettingsLanguageComponent);
  }

  selectTreatmentPlace() {
    const nav = document.querySelector('ion-nav');
    nav?.push(SettingsTreatmentPlaceComponent);
  }

  selectMedicalTrail() {
    const nav = document.querySelector('ion-nav');
    nav?.push(SettingsMedicalTrailComponent);
  }
}
