import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonContent,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-settings-treatment-place',
  templateUrl: './settings-treatment-place.component.html',
  styleUrls: ['./settings-treatment-place.component.scss'],
  imports: [
    RouterLink,
    TranslateModule,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonContent,
    IonList,
    IonItem,
    IonRadioGroup,
    IonRadio,
  ],
})
export class SettingsTreatmentPlaceComponent implements OnInit {
  currentTreatmentPlace: string | undefined = undefined;

  constructor(private stateService: StateService) {}

  ngOnInit() {
    this.stateService.currentTreatmentPlace$.subscribe((currentTreatmentPlace) => {
      this.currentTreatmentPlace = currentTreatmentPlace;
    });
  }

  setTreatmentPlace(event: any) {
    this.stateService.setCurrentTreatmentPlace(event.detail.value);
  }
}
