import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../services/state.service';
import { SafeHtmlPipe } from '../../services/safe-html.pipe';
import { IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-article',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  imports: [SafeHtmlPipe, IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent],
})
export class ArticlePageComponent implements OnInit {
  title: string | undefined = undefined;
  content: string = '';
  icon: string | undefined = undefined;
  treatmentPlace: string | undefined = undefined;
  medicalTrail: string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.stateService.articles$.subscribe(() => this.setupArticleContent());
    this.stateService.currentTreatmentPlace$.subscribe((treatmentPlace) => {
      this.treatmentPlace = treatmentPlace;
    });
    this.stateService.medicalTrail$.subscribe((medicalTrail) => {
      if (medicalTrail?.includes('enrolled-with-video')) {
        this.medicalTrail = 'enrolled-with-video';
      } else if (medicalTrail?.includes('enrolled-without-video')) {
        this.medicalTrail = 'enrolled-without-video';
      } else {
        this.medicalTrail = medicalTrail;
      }
    });
  }

  async setupArticleContent() {
    const article = this.stateService.getArticle(this.route.snapshot.paramMap.get('slug') || '');
    if (!article) {
      await this.router.navigate(['']);
      return;
    }
    const { menutitle, content, icon } = article;
    this.title = menutitle;
    this.content = content;
    this.icon = icon;
  }
}
